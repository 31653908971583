import { Component, Directive, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
// Import Interface
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { IAlert } from '../../models/alert';
import { IMeasurementData } from '../../models/measurement';
import { ChartStoreService } from '../../services/chart.service';
// import service
import { PanelToggleService } from '../../services/panel-toggle.service';
import { SignalRService } from '../../services/signal-r.service';
import * as userActions from '../../state/actions/userActions';
import * as fromAlert from '../../state/container-states/alert.state';
import { State } from '../../state/container-states/app.state';
import { Well } from '../../state/reducers/global-filter.reducer';
import sensorInfo from './../../../assets/data/sensorTypeConfig.json';
import { MeasurementConstants, NicknameConstants } from '../../shared/constants/master-page.constants';
import { WellService } from 'src/app/services/well.service';
import * as globalFilterActions from './../../state/actions/global-filter.actions';
import * as fromFilter from './../../state/container-states/global-filter.state';

@Component({
  selector: 'app-sensor-display',
  templateUrl: './sensor-display.component.html',
  styleUrls: ['./sensor-display.component.scss']
})
export class SensorDisplayComponent implements OnInit, OnDestroy, DoCheck  {
  // Data as input from parent
  @Input()
  measurementData: IMeasurementData;
  // Individual pressure temperature data
  @Input()
  displayData;

  @Input()
  color: string;
  @Input()
  isSchematic = false;
  @Input()
  showColor: 'hidden';

  @Input()
  isAlert = false;

  @Output() activeAlerts = new EventEmitter<{ alertTriggered: boolean, alertLength: number, alertList: any }>();
  // display string
  public temperatureSensorLabel: string;
  // display string
  public pressureSensorLabel: string;
  // display string
  public editValue: string;
  // boolean for display
  public hideTemperature: boolean;
  // boolean for display
  hideSensor: boolean = false;
  // boolean for sections
  showPressure: boolean;
  showEditPress: boolean;
  showEditTemp: boolean;
  wells: Well[];
  sensorEditedName: string;
  unsubscribe$ = new Subject();
  liveReadingAlerts = {};
  wellInformation = {};
  showSensorNameAlert = false;
  labelError= '';
  currentAlerts: any;
  sensorEditedTruncated: string;
  mappedSensorColor: any;
  axisColor: string;
  showMultiple: boolean;
  indicator: any;
  fourGreens: boolean;
  threeGreens: boolean;
  twoGreens: boolean;
  red: boolean;
  showBatteryColor: boolean;
  batteryPercentage: any;
  displayValue: any;
  sensorInfo: any[];
  roles: string[];

  constructor(
    private store: Store<State>,
    private panelToggleService: PanelToggleService,
    private formStore: Store<fromAlert.AlertPageState>,
    private filterState: Store<fromFilter.GlobalFilterState>,
    private chartStoreService: ChartStoreService,
    private alertState: Store<fromAlert.AlertPageState>,
    private signalRService: SignalRService,
    private wellService: WellService
  ) {
    this.sensorInfo = sensorInfo.sensorData;
  }

  ngDoCheck(): void {
    //  ngDoCheck is used to detect any changes in key value pair of an object
    //  Here used for checking any changes in the displaydata object
    if (this.displayData) {
      let unit;
      let name;
      this.sensorInfo.forEach((sensorList) => {
        if (this.displayData['sensorType'] === sensorList.sensorType) {
          name = sensorList.axisName;
          sensorList.sensorUnits.forEach((sensorUnit) => {
            if (sensorUnit.value == this.measurementData[this.displayData['sensorType'] + 'Unit']) {
              unit = sensorUnit.label;
            }
          });
        }
      });
      //convert battery value of the sensor from signal-R into visual
      if(this.displayData.battery){
        this.showBatteryColor = true;
        this.batteryIndicator(this.displayData.battery);
      }
      this.displayValue = {
        name: name && name.charAt(0).toUpperCase() + name.slice(1),
        value: this.displayData[this.displayData['sensorType']],
        md:this.displayData.md,
        type: this.displayData.sensorType,
        unit
      };
      let selectedWell = this.getWellInformation(this.displayData);
      this.sensorEditedName = this.displayData.nickName ? this.displayData.nickName : this.displayData.sensorLabel;
      const wellName = this.displayData.wellName ? this.displayData.wellName : this.getWellInformation(this.displayData)[0]['nameWell'];
      //this.loadSensorEditedNames(this.displayData.sensorOriginalName, wellName);
      this.sensorEditedTruncated  = this.start_and_end(this.sensorEditedName);
    }
  }

  public ngOnInit(): void {

    this.formStore
    .pipe(select(fromAlert.getAlertRules), takeUntil(this.unsubscribe$))
    .subscribe((alerts: IAlert[]) => {
      if (alerts) {
        this.currentAlerts = alerts;
        this.liveReadingAlerts = {};
        _.map(alerts, (alert) => {
          const sensorName = alert.AlarmDetail.MnemonicName.toUpperCase();
          if (alert.AlarmDetail.NotificationStatus || alert.AlarmDetail.AlarmNotificationStatus) {
            this.liveReadingAlerts[alert.UidLog + sensorName] = '#cc0000';
            this.activeAlerts.emit({alertTriggered: true, alertLength: alerts.length, alertList: alerts});
          } else {
            if (!this.liveReadingAlerts[alert.UidLog + sensorName]) {
              this.liveReadingAlerts[alert.UidLog + sensorName] = 'green';
            } else if (this.liveReadingAlerts[alert.UidLog + sensorName] !== '#cc0000'){
              this.liveReadingAlerts[alert.UidLog + sensorName] = 'green';
            }
          }
        });
      }
    });

    this.store
      .select((state: State) => state.globalFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((wellState) => {
        this.wells = wellState.wells;

        this.wells.map((well) => {
          this.wellInformation[well.uid] = well.wellName;
        });
      });

      //get the user roles
      this.store
      .select((state: State) => state.user)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userState) => {
        this.roles = userState.roles;
      });

    this.showPressure = false;
    this.showMultiple = false;

    this.panelToggleService.currentSelection.subscribe((payload) => {
      if (payload.typeSelected) {
        if (payload.typeSelected.toLocaleLowerCase() === 'multiple') {
          this.showMultiple = true;
        }
        else {
          this.showMultiple = false;
        }
        if (this.displayData) {
          this.showPressure = (this.displayData['sensorType'] === payload.typeSelected) ? true : false;
        }
      }
    });

    this.chartStoreService.currentColorMap.pipe(takeUntil(this.unsubscribe$)).subscribe((colorMap) => {
      this.mappedSensorColor =  colorMap;
        let multiLogId = this.displayData ?  this.displayData.multiLogId : '';
        this.axisColor = this.getMappedColor(multiLogId + ' - ' + this['displayData'].sensorName);

    });

  }
 // Truncate function start here
  start_and_end(sensorEditedName) {
    if (sensorEditedName.length > 31 && sensorEditedName) {
     return sensorEditedName.substr(0, 12) + '...' + sensorEditedName.substr(sensorEditedName.length - 16, sensorEditedName.length);
    }
    return sensorEditedName;
  }
  //Function to get index for voltage data starts here
  getIndexForVoltage(mnemonicList) {
     return mnemonicList.indexOf(this.displayData['batterySensorName']);
  }
   //Function to get index for voltage data ends here
  getMappedColor(wellSensorName) {
    const sensorColor = _.filter(this.mappedSensorColor, (item) => {
      return item.wellSensorName === wellSensorName;
    });
    if (sensorColor[0]) {
      return sensorColor[0]['sensorAxisColor'];
    } else {
      return 'white';
    }
  }



  /**
   * @name: getAlertReadings
   * @description: get live reading alerts
   * @param: {object} get list of object in display data
   * {array} liveReadingAlerts contains list of alerts
   */
  getAlertReadings(displayData, liveReadingAlerts) {
    return liveReadingAlerts[displayData.multiLogId + displayData.sensorName.toUpperCase()];
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * @name: editData
   * @description: method to open edit on click of button
   * @param: {string} unitSelected: selected value for edit
   * {string} typeOfLabel: denotes temp or pressure
   */
  public editData(typeOfLabel: string): void {
    this.clearStatusLabel();
    this.hideSensor = true;
    this.sensorInfo.forEach((sensorList) => {
      if (sensorList.sensorType.toLowerCase() === typeOfLabel.toLowerCase()) {
        this.editValue = this.sensorEditedName;
      }
    });
  }

  public deleteNickname(){
    
      this.showStatusLabel(NicknameConstants.RESTORE_SENSOR_NAME);
        let selectedWell = this.getWellInformation(this.displayData);
        this.wellService.deleteNickname(selectedWell["uidWell"],this.displayData["sensorName"]).subscribe((result: any) => {
          this.showStatusLabel(NicknameConstants.RESTORE_SUCCESS);
          let wellToUpdate = {
            uidWell : selectedWell["uidWell"],
            sensorName : this.displayData["sensorName"],
            nickName : result
          }
          this.displayData["nickName"] = this.displayData["sensorOriginalName"];
          this.displayData['sensorLabel'] = this.displayData["sensorOriginalName"];
          this.store.dispatch(new globalFilterActions.UpdateNickName(wellToUpdate));
          this.clearStatusLabel();
        },
        ((err)=>{
          this.showStatusLabel(NicknameConstants.RESTORE_FAILED);
          this.clearStatusLabel();
        }));
     
      this.hideSensor = false;
      this.showEditPress = false;
      this.sensorEditedTruncated  = this.start_and_end(this.sensorEditedName);
      this.chartStoreService.changeRenameStatus(true);
    
  }

  public clearStatusLabel(){
    setTimeout(() => {
      this.showSensorNameAlert = false;
      this.labelError = '';
    }, 5000);
  }

  public showStatusLabel(text:string){
      this.labelError = text;
      this.showSensorNameAlert = true;
  }

  /**
   * @name: updateValue
   * @description: Method to catch output from label and update value
   * @param: {string}: valueEntered: value emitted
   * {string}: labelChanged: type of label changed
   */
  public updateValue(valueEntered: string) {
    this.showSensorNameAlert = false;
    this.labelError = '';
    if (valueEntered && valueEntered !== '') {
    
      let selectedWell = this.getWellInformation(this.displayData);
      
      //check if any other sensor of the selected well has the same nickname
      if (this.checkForDuplicateNames(selectedWell, valueEntered)) {
        this.showStatusLabel(NicknameConstants.NICKNAME_EXISTS);
        return;
      }
      this.showStatusLabel(NicknameConstants.UPDATING_NICKNAME);
      this.displayData["nickName"] = valueEntered;
      this.wellService.addNickName(selectedWell["uidWell"],this.displayData["sensorName"],valueEntered.toString()).subscribe((result: any) => {
        this.showStatusLabel(NicknameConstants.UPDATE_SUCCESS);
        let wellToUpdate = {
          uidWell : selectedWell["uidWell"],
          sensorName : this.displayData["sensorName"],
          nickName : result
        }
        this.store.dispatch(new globalFilterActions.UpdateNickName(wellToUpdate));
        this.clearStatusLabel();
      },
      ((err)=>{
        this.showStatusLabel(NicknameConstants.NICKNAME_UPDATE_FAILED)
        this.clearStatusLabel();
      }));

      this.displayData['sensorLabel'] = valueEntered;
      this.sensorEditedName = this.displayData.sensorLabel; // fix for rename and cancel
    }

    this.editValue = '';
    this.hideSensor = false;
    this.showEditPress = false;
    this.sensorEditedTruncated  = this.start_and_end(this.sensorEditedName);
    this.chartStoreService.changeRenameStatus(true);
  }

  closeErrorDialog() {
    this.showSensorNameAlert = false;
  }

  updateSchematics(displayData){
    this.signalRService.emitIsHovered(displayData);
  }
  
  //check if any other sensor of same well has the same nickname
  public checkForDuplicateNames(selectedWell:Well, value:string):boolean{
    let isDuplicate = false;
    const currentWell = this.wells.find(obj => obj.uidWell === selectedWell.uidWell );
    if(currentWell){
      isDuplicate =  currentWell.sensors.some(switchObj => switchObj.nickName === value);
    }
    return isDuplicate;
  }

  /**
   * @name: setClassOpacity
   * @description: Method set the opacity of other classes on edit
   * @param: {number} opacityValue: the value of opacity to be set
   */
  public setClassOpacity(opacityValue: number): void {
    const classesToOverride = document.querySelectorAll('.measurement-data');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < classesToOverride.length; i++) {
      classesToOverride[i]['style'].opacity = opacityValue;
    }
  }

  public insertRenamedSensorInDB(newLabel: string, oldLabel: string, wellInformation: any) {
    const payload = {
      newLabel,
      oldLabel,
      wells: this.wells,
      currentWell: wellInformation
    };
    this.store.dispatch(new userActions.EditUserSensorName(payload));
  }

  getWellInformation(displayData) {
    return this.wells.find(well => well.uid === displayData.logid);
  }

  loadSensorEditedNames(sensorOriginalName: string, sensorWellName: string) {
    this.store
      .select((state: State) => state.user)
      .pipe(first())
      .subscribe((user) => {
        if (
          user &&
          user.preferences &&
          user.preferences.length > 0
        ) {
          const userSensors = user.preferences;
          userSensors.map((userSensor) => {
            if (userSensor.originalSensorLabel === sensorOriginalName && userSensor['sensorWellName'] === sensorWellName) {
              this.sensorEditedName = userSensor.renamedSensorLabel;
            }
          });
        }
      });
  }

  // Function to show battery level indicator
  batteryIndicator(currentValue: string) {
    if (currentValue === null) { return; }
    this.batteryPercentage = currentValue;
    this.fourGreens = this.threeGreens = this.twoGreens = this.red = false;

    if (this.batteryPercentage > 75) {
      this.fourGreens = true;
    } else if (this.batteryPercentage > 50 && this.batteryPercentage <= 75) {
      this.threeGreens = true;
    } else if (this.batteryPercentage > 25 && this.batteryPercentage <= 50) {
      this.twoGreens = true;
    } else if (this.batteryPercentage <= 25) {
      this.red = true;
    }
    this.batteryPercentage = this.batteryPercentage.toFixed(0) + MeasurementConstants.BATTERY_UNIT;
  }

}
